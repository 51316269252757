import './Message.css'

const Message = ({id, author, message}) => {
  return (
    <div className={`message ${author === 'Anna' ? 'message--left' : 'message--right'}`}>
      <p>
        {message}
      </p>
    </div>
  )
}

export default Message