import React from 'react';
import { DOTS, usePagination } from "../../../hooks/usePagination";
import './Pagination.css';

const Pagination = props => {
  const {
    totalPageCount,
    currentPage,
    onPageChange,
  } = props;

  const paginationRange = usePagination({
    totalPageCount,
    currentPage
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  const lastPage = paginationRange[paginationRange.length - 1];

  return (
    <div className="pagination">
      <button
        className={`pagination__item ${currentPage === 1 && 'pagination__item--disabled'}`}
        disabled={currentPage === 1}
        onClick={onPrevious}
      >
        <span>Previous</span>
      </button>
      {paginationRange.map(pageNumber => {
        if (pageNumber === DOTS) {
          return <button className="pagination__item">
            <span>
              &#8230;
            </span>
          </button>;
        }

        return (
          <button className={`pagination__item ${pageNumber === currentPage && 'pagination__item--selected'}`}
                  onClick={() => onPageChange(pageNumber)}>
            <span>{pageNumber}</span>
          </button>
        );
      })}
      <button
        className={`pagination__item ${lastPage === currentPage && 'pagination__item--disabled'}`}
        disabled={lastPage === currentPage}
        onClick={onNext}
      >
        <span>Next</span>
      </button>
    </div>
  );
};

export default Pagination;