import React, { useEffect, useState } from 'react';
import './Chat.css';
import Pagination from "./Pagination/Pagination";
import axios from "axios";
import Message from "./Message/Message";

const Chat = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPageCount, setTotalPageCount] = useState(undefined);
  const [messages, setMessages] = useState(undefined);

  useEffect(() => {
    axios.get(`https://testapi.codesignal.com/countOccurrencesInMessages/messages?page=${currentPage}`).then(res => {
      setMessages(res.data.data)
      setTotalPageCount(res.data.totalPages)
    })
  }, [currentPage])

  return messages && totalPageCount
    ? (
      <>
        <div className="messages">
          {messages.map(m => (<Message key={m.id} {...m} />))}
        </div>
        <Pagination
          totalPageCount={totalPageCount}
          currentPage={currentPage}
          onPageChange={page => setCurrentPage(page)}
        />
        {currentPage > 10 && (
          <div style={{opacity: 0}}>
            <p>Hidden CodeSignal easter egg which should make your task a bit harder😈</p>
          </div>
        )}
      </>
    )
    : null;
};

export default Chat
